<template>
  <vf-notification v-if="success" class="mb-4" :dismissible="false" type="success">
    {{ $t.forgotPasswordSuccessMessage }}
  </vf-notification>
  <base-button v-if="insideDialog" :class="backButton" @click="signIn">
    <vf-icon dir="left" name="chevron" size="md" />
    <span class="text-sm">{{ $t.backToSignIn }}</span>
  </base-button>
  <div data-test-id="form-reset-password-request">
    <h2 :class="brandClasses.heading">
      {{ $t.forgotPasswordTitle }}
    </h2>
    <base-form :class="brandClasses.form" :form validate-on="submit" @submit="submit">
      <vf-form-field
        v-slot="{ invalid }"
        :hint="$t.emailFormatHint"
        name="username"
        :rule="[validateRequired($t.email), validateEmail()]"
      >
        <vf-input v-model="form.username" :invalid required type="email">
          {{ $t.email }}
        </vf-input>
      </vf-form-field>
      <vf-button :class="brandClasses.submitButton" :loading :size="buttonSize" type="submit">
        {{ $t.resetPassword }}
      </vf-button>
    </base-form>
  </div>
</template>

<script lang="ts" setup>
const {
  email = '',
  insideDialog,
  preventDialog
} = defineProps<{
  email?: string
  insideDialog?: boolean
  preventDialog?: boolean
}>()

const emit = defineEmits<Emits>()
export type Emits = { signIn: [] }

const { brandClasses, buttonSize } = useAppConfig().components.form.forgotPassword
const { backButton } = useAppConfig().components.dialog.forgotPassword.brandClasses
const { validateEmail, validateRequired } = useLocalizedValidators()
const recaptcha = useRecaptcha()
const { DialogSignIn } = useDialogsStore()

const form = reactive({
  username: email
})
const loading = ref(false)
const success = ref(false)

async function submit() {
  loading.value = true
  const captchaResponse = await recaptcha.execute('resetPassword')
  const { username } = form

  await useApi().authentication.$resetPassword({
    username,
    recaptcha_response: captchaResponse
  })

  loading.value = false
  success.value = true
  form.username = ''
}

const signIn = () => {
  emit('signIn')
  if (!preventDialog) DialogSignIn.open()
}
</script>
